import React, { useEffect, useState } from "react";
import { loadImage } from "./Const";
import { Link } from "react-router-dom";
import { initializeWow, cleanupWow } from './Const';
import { ModalContactKeepe } from "./ModalContactKeepe";

const Footer = () => {

  useEffect(() => {
    initializeWow();
    return () => {
      cleanupWow();
    };
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <footer className="footer-wrap">
        <div className="footer-top py-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-4 col-lg-4 col-md-4">
                <div
                  className="footer-logo mb-4 wow animate__animated animate__fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <img src={loadImage("keepe.png")} alt="keepe" />
                </div>
                <div
                  className="title-lg text-start fw-700 mb-5 wow animate__animated animate__fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="1s"
                >
                  Want to Learn <br />
                  More?
                </div>        
                <button
                  className="theme-btn gradient-btn sm-btn wow animate__animated animate__fadeInLeft"
                  type="button"
                  onClick={handleShow}
                >
                  Contact Keepe
                </button>

              </div>
              <div className="col-xl-8 col-lg-8 col-md-8">
              <h4 className="text-white text-center wow animate__animated animate__zoomIn mb-5"
              data-wow-duration="1s"
              data-wow-delay="0.5s"><strong>OFFICE</strong></h4>
                <div
                  className="row gx-5 align-items-end wow animate__animated animate__fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >              
                  <div className="col-md-6 col-sm-6 mt-2 ">
                    <div className="city-img-row-1 text-center">
                      <img
                        className="footer-address-img"
                        src={loadImage("seattle.png")}
                        alt="seattle"
                      />
                    </div>
                    <h4 className="text-white mt-2 text-center">
                      <strong>SEATTLE</strong>
                    </h4>
                  </div>
                 
                  <div className="col-md-6 col-sm-6">
                    <div className="city-img-row-1 text-center">
                      <img
                        src={loadImage("city-london-light.png")}
                        alt="LONDON"
                      />
                    </div>
                    <h4 className="text-white mt-2 text-center">
                      <strong>LONDON</strong>
                    </h4>
                  </div>
                  
                </div>
                <div
                  className="row gx-5 align-items-end mt-5 wow animate__animated animate__fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay="1s"
                >
                  <div className="col-md-4 col-sm-4">
                    <div className="city-img">
                      <img
                        src={loadImage("jaipur.png")}
                        alt="jaipur"
                      />
                    </div>
                    <h4 className="text-white mt-2 text-center city-heading">
                      <strong>JAIPUR</strong>
                    </h4>
                  </div>
                 
                  <div className="col-md-4 col-sm-4">
                    <div className="city-img">
                      <img
                        src={loadImage("kingston.png")}
                        alt="kingston"
                         className="footer-address-img"
                      />
                    </div>
                    <h4 className="text-white mt-2 text-center city-heading">
                      <strong>KINGSTON</strong>
                    </h4>
                  </div>
                  
                  <div className="col-md-4 col-sm-4">
                    <div className="city-img">
                      <img
                        src={loadImage("manila.png")}
                        alt="manila"
                        className="footer-address-img"
                      />
                    </div>
                    <h4 className="text-white mt-2 text-center city-heading">
                      <strong>MANILA</strong>
                    </h4>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="fb-title d-md-none">Menu</div>
                <ul className="fb-menu">
                  <li>
                    <Link to="/about">Our Story</Link>
                  </li>
                  <li>
                    <Link to="/support">Contact us</Link>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://wellfound.com/company/keepe/jobs">
                      Jobs WE'RE HIRING!
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6">
                    <ul className="fb-menu">
                      <li>
                        <Link to="/contactus?type=tech&utm_source=home&utm_medium=keepe-header&utm_term=home&utm_content=home&utm_campaign=manager-landing-home">Become a Keeper</Link>
                        </li>
                        <li>
                          <a target="_blank" rel="noopener noreferrer" href="mailto:partner@keepe.com">Partner with Us</a>
                        </li>
                        <li>
                          <a rel="noopener noreferrer" href="https://blog.keepe.com/">Blog</a>
                        </li>

                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="fb-menu">

                      <li>
                        <Link to="/legal/us/terms">Terms of Use</Link>
                      </li>
                      <li>
                        <Link to="/legal/us/privacy">Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-block">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <div className="social-links order-2">
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/keepeapp/">
                  <i className="fab fa-instagram-square"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/keepeapp">
                  <i className="fab fa-facebook"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/keepeapp">
                  <i className="fab fa-twitter"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/keepe">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
              <div className="copyright">
                &copy; 2025 Keepe all rights reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ModalContactKeepe
       modalIsOpen={show}
       closeModal={handleClose}
      />
    </>
  );
};
export default Footer;