import React from "react";
import { loadImage } from "./Const";
import Marquee from "react-fast-marquee";

const OurClient = () => {
  return (
    <section className="overflow-hidden">
      <section className="client-wrap py-10">
        <div className="title-lg text-center fw-700 mb-5 pb-4 wow animate__animated animate__zoomIn"
          data-wow-duration="1s"
          data-wow-delay="0.5s">
          Client Features
        </div>
        <div className="client-flex wow animate__animated animate__zoomIn"
          data-wow-duration="1s"
          data-wow-delay="1s">
          <Marquee speed={29}>
            <div className="cf-col">
              <img src={loadImage("client/OncourseHomeSolutions.png")} alt="OncourseHomeSolutions" />
            </div>
            <div className="cf-col">
              <img src={loadImage("client/Allied.png")} alt="Allied-logo" />
            </div>
            <div className="cf-col">
              <img src={loadImage("client/Fortegra.png")} alt="Fortegra" />
            </div>
            <div className="cf-col">
              <img src={loadImage("client/Latchel.png")} alt="Latchel" />
            </div>
            <div className="cf-col">
              <img src={loadImage("client/Domestic-general.png")} alt="Domestic-general" />
            </div>
            <div className="cf-col">
              <img src={loadImage("client/Hemlane.png")} alt="Hemlane" />
            </div>
          </Marquee>

          <Marquee speed={20}>
            <div className="cf-col">
              <img src={loadImage("client/ServicePlus.png")} alt="ServicePlus" />
            </div>
            <div className="cf-col">
              <img src={loadImage("client/2-10HomeWarranty.png")} alt="2-10HomeWarranty" />
            </div>
            <div className="cf-col">
              <img src={loadImage("client/MyndManagement.png")} alt="MyndManagement" />
            </div>
            <div className="cf-col">
              <img src={loadImage("client/NrgProtects.png")} alt="NrgProtects-logo" />
            </div>
            <div className="cf-col">
              <img src={loadImage("client/MikeHolmesProtection.png")} alt="MikeHolmesProtection" />
            </div>
            <div className="cf-col">
              <img src={loadImage("client/dispatch.png")} alt="dispatch" />
            </div>
          </Marquee>
        </div>
      </section>
    </section>
  );
};
export default OurClient;
